<template>
  <div class="l-scratch">
    <main class="l-section l-scratch__iframe">
      <iframe
        v-if="spincity[gameName]"
        id="mainframe"
        class="l-section l-scratch__iframe-img"
        :src="spincity[gameName].uri"
      ></iframe>
      <!--<div class="c-iframe__actions">
        <button
          aria-label="filter-week-preview"
          class="c-btn c-btn--padding-0 c-iframe__arrow is-for-top"
          @click="scrollBot"
        >
          <img :src="require('../../assets/svg-icons/icon-triangle.svg')" class="c-iframe__arrow-icon"  />
        </button>
        <button
          aria-label="filter-week-preview"
          class="c-btn c-btn--padding-0 c-iframe__arrow is-for-bot"
          @click="scrollTop"
        >
          <img :src="require('../../assets/svg-icons/icon-triangle.svg')" class="c-iframe__arrow-icon"  />
        </button>
      </div>-->
    </main>
    <div style="
        z-index: 0;
        position: relative;"
    >
      <other-games class="l-section l-scratch__swiper" />
    </div>
  </div>
</template>

<script>
import OtherGames from "../../components/other-games/other-games";

import { spincity } from "../../constants/index";
import backend from "../../classes/backend.js";
export default {
  name: "GamePage",
  components: {
    OtherGames,
  },
  data() {
    return {
      spincity,
      nextUser: {},
      isMobile: false,
      nextChips: []
    };
  },
  computed: {
    gameName() {
      return this.$route.params.gameId.split("-").join("");
    },
    loggedIn() {
      return this.$store.getters.getLoginData != null;
    },
    chips() {
      return this.$store.getters.getChipsBalance;
    },
    unlockedGames() {
      return this.$store.getters.getUnlockedGames;
    }
  },
  watch: {
    chips(value) {
      if (this.gameWindow) {
        this.gameWindow.postMessage(
          JSON.stringify({ action: "balance", balance: value }),
          "*"
        );
      }
    },
  },
  async created() {
    window.addEventListener("message", this.receiveMessage, false);
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("message", this.receiveMessage);
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    if(!localStorage.getItem("loginData")) {
      this.$router.push('/');
    }
    this.scrollUp();
    this.isMobile = window.innerWidth <= 820

    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth <= 820
    });
    // if(this.isMobile){
    //   let elem = document.getElementById("mainframe");
    //   if (elem.requestFullscreen) {
    //     elem.requestFullscreen();
    //   } else if (elem.webkitRequestFullscreen) { /* Safari */
    //     elem.webkitRequestFullscreen();
    //   } else if (elem.msRequestFullscreen) { /* IE11 */
    //     elem.msRequestFullscreen();
    //   }
    // }
  },
  methods: {
    scrollBot() {
      window.scroll(0, 0);
    },
    scrollUp() {
      const scrollTo = document.getElementById("header");
      if(scrollTo) {
        scrollTo.scrollIntoView();
      }
    },
    scrollTop() {
      window.scrollBy(0, 300);
    },
    async receiveMessage(message) {
      if (typeof message.data !== "string") {
        return;
      }
      const data = JSON.parse(message.data);
      switch (data.action) {
        case "buyChips": {
          this.$store.commit('setBuyChipsPopupOpen', true);
          break;
        }
        case "home": {
          this.$router.push("/");
          break;
        }
        case "soundOn": {
          this.$store.commit("setSound", "on");
          break;
        }
        case "soundOff": {
          this.$store.commit("setSound", "off");
          break;
        }
        case "init":{
          while(!this.$store.getters.getLoginData){
            await backend.sleep(100);
          }
          // let data = await backend.loginSpincity(this.$store.getters.getLoginData)
          let initData = {
            loginData: this.$store.getters.getLoginData,
            env: this.getEnv(),
            sound: this.$store.getters.getSound
          }
          initData.action="init"
          console.log(initData);
          message.source.postMessage(JSON.stringify(initData), "*");
          break;
        }
        case "openshop":{
          console.log("getting state");
          let state = await backend.loginSpincity(this.$store.getters.getLoginData);
          console.log(state);
          message.source.postMessage(JSON.stringify(state.buildings), "*");
          break;
        }
      }
      if(data == 'betComplete' || (data && data.action && data.action == 'betComplete')) {
        this.$store.commit("setChips", this.$store.getters.getChipsBalance + this.nextChips.pop());
      }

    },
    goFullscreen: function (){
      if (this.isMobile){
        let elem = document.getElementById("mainframe");
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen();
        }
      }
    },
    handleScroll (event) {
      //console.log(event);
    },

    getEnv() {
      let url = window.location.hostname;
      if(url.includes("test.") || url.includes("localhost")) {
        return "test";
      } else {
        return "prod";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.l-scratch__iframe {
  position: relative;
  border-radius: 30px;
  z-index: 1;
}
.l-scratch__iframe-img {
  width: 100%;
  height: 90vh;
  border: none;
}
.c-iframe__actions {
  position: fixed;
  top: 348px;
  left: 10px;
  z-index: 20;
  display: none;
  pointer-events: none;
  @include media-min($screen-tablet-portrait) {
    display: block;
    pointer-events: all;
  }
}
.c-iframe__arrow {
  display: block;
  width: 37px;
  height: 37px;
  transform: rotate(-90deg);
  &.is-for-bot {
    transform: rotate(90deg);
    margin-top: 13px;
    > svg {
      margin-left: 2px;
      margin-right: 0;
    }
  }
  > svg {
    margin-bottom: -2px;
    margin-right: 1px;
  }
}
.c-iframe__arrow-icon {
  width: 13px;
  height: 15px;
}
.l-scratch__swiper {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
  background: linear-gradient(281.53deg, #7A00FF 5.6%, #00FFF0 111.24%), linear-gradient(281.53deg, #5802B5 5.6%, #0085FF 111.24%);
}
.l-scratch__recent-bets-title-wrap{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 10px;

  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 115%;

  width: 327px;
  height: 48px;
  margin-bottom: 30px;
}
.l-scratch__recent-bets-title{
  height: 48px;
  /* or 48px */

  letter-spacing: -0.03em;
  text-transform: uppercase;

  background: linear-gradient(281.53deg, #7A00FF 5.6%, #00FFF0 111.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.l-scratch__recent-bets {
  @include media-min($screen-tablet-portrait) {
    padding: 0 40px;
  }
  @include media-min($screen-tablet-landscape-lg) {
    padding: 0 120px;
  }
  margin-bottom: 150px;
  position: relative;
}
#svg_coin_2{
  position: absolute;
  width: 93px;
  height: 65px;
  right: 0;
  bottom: 345px;
  transform: rotate(50deg);
  @include media-min($screen-tablet-portrait) {
    opacity: 0;
  }
}
</style>